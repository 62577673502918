import React from "react"
import MarkdownViewer from "@bit/azheng.joshua-tree.markdown-viewer"
import { replaceStringVars, cleanURL } from "../../utils/utils"

const PrivacyPagesHandler = props => {
  const locations = props.locations

  const stringVariables = {
    EMAIL: locations[0].locationData.email,
    PRACTICE_NAME: props.practiceName,
    LOCATIONS: makeLocationList(locations),
    PRACTICE_URL: cleanURL(props.practiceUrl),
    DOCUMENT_UPDATED_ON: props.documentUpdatedOn,
    STATE_NAME: locations[0].locationData.stateName
  }

  const compiled = replaceStringVars(props.body, stringVariables)

  return (
    <>
      <h1>{props.name}</h1>
      <MarkdownViewer markdown={compiled} />
    </>
  )
}

// For bottom of privacy-policy page
const makeLocationList = locations => {
  let locationList = ""
  locations.forEach(locale => {
    const l = locale.locationData

    locationList +=
      "<p><b>" +
      // l.locationName + (braces omaha single location fix)
      "Braces Omaha" +
      "</b><br>" +
      l.streetNumber +
      " " +
      l.streetName +
      "<br>" +
      l.cityName +
      ", " +
      l.stateAbbr +
      " " +
      l.cityZip +
      "</p>"
  })
  return locationList
}

export default PrivacyPagesHandler
